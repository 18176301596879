import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { formatAsUSD } from "../../../utils/moneyUtils";
import {
  calculateDaysBetweenDates,
  calculateDaysBetweenDatesText,
} from "../../../utils/datesUtils";

const DetailSummaryInnerTable = ({ activeProduct }) => {
  const lowestCostProduct =
    activeProduct.ProductsChosen.PriceChosen.Regular.reduce((prev, current) =>
      prev.Cost < current.Cost ? prev : current
    );

  const daysBetweenDates = calculateDaysBetweenDates(
    activeProduct.StartDate,
    activeProduct.EndDate,
    activeProduct.ProductsChosen.PriceChosen.Frequency
  );

  const totalCost =
    (activeProduct.ProductsChosen.NumberOfSpots || 1) *
    (lowestCostProduct.Cost || 0) *
    (activeProduct.ProductsChosen.PriceChosen.Regular[0].Type === "YMS"
      ? 1
      : daysBetweenDates);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ px: 1 }}>
            <strong>Product</strong>
          </TableCell>
          <TableCell sx={{ px: 1 }}>
            <strong>Quantity</strong>
          </TableCell>
          <TableCell sx={{ px: 1 }}>
            <strong>Price</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activeProduct.ProductsChosen.PriceChosen.Regular[0].Type === "YMS" ? (
          activeProduct.ProductsChosen.PriceChosen.Regular.map(
            (price, index) => (
              <TableRow
                key={index}
                sx={{ borderBottom: 2, borderColor: "background.default" }}
              >
                <TableCell sx={{ px: 1 }}>{price.Description}</TableCell>
                <TableCell sx={{ px: 1 }}>
                  {price.Type !== "Yard-Package" ? "1" : "1-100"}
                </TableCell>
                <TableCell sx={{ px: 1 }}>{formatAsUSD(price.Cost)}</TableCell>
              </TableRow>
            )
          )
        ) : (
          <TableRow sx={{ borderBottom: 2, borderColor: "background.default" }}>
            <TableCell sx={{ px: 1 }}>
              {activeProduct.ProductsChosen.YardSelected.CodeName
                ? activeProduct.ProductsChosen.YardSelected.CodeName
                : activeProduct.ProductsChosen.YardSelected}
              / {activeProduct.ProductsChosen.PriceChosen.Frequency}
            </TableCell>
            <TableCell sx={{ px: 1 }}>
              {activeProduct.ProductsChosen.NumberOfSpots === 0
                ? 1
                : activeProduct.ProductsChosen.NumberOfSpots}
            </TableCell>
            <TableCell sx={{ px: 1 }}>
              {formatAsUSD(lowestCostProduct.Cost)}
            </TableCell>
          </TableRow>
        )}
        {activeProduct.ProductsChosen.PriceChosen.Regular[0].Type !== "YMS" && (
          <>
            <TableRow>
              <TableCell sx={{ px: 1 }}>
                <strong>Billing</strong>
              </TableCell>
              <TableCell sx={{ px: 1 }}></TableCell>
              <TableCell sx={{ px: 1 }}></TableCell>
            </TableRow>
            <TableRow
              sx={{ borderBottom: 2, borderColor: "background.default" }}
            >
              <TableCell sx={{ px: 1 }}>
                {activeProduct.StartDate} - {activeProduct.EndDate}
              </TableCell>
              <TableCell sx={{ px: 1 }}>
                {calculateDaysBetweenDatesText(
                  activeProduct.StartDate,
                  activeProduct.EndDate
                )}
              </TableCell>
              <TableCell sx={{ px: 1 }}>{formatAsUSD(totalCost)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ px: 1 }}></TableCell>
              <TableCell sx={{ px: 1 }}>
                <strong>Total</strong>
              </TableCell>
              <TableCell sx={{ px: 1 }}>
                <strong>{formatAsUSD(totalCost)}</strong>
              </TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default DetailSummaryInnerTable;
