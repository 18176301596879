/**
 * LocalStorageService
 * @description Service for managing data in localStorage
 * @method getItemFromLocalStorage
 
 * @method removeItemFromCart
 * @method editItemInLocalStorage
 * @method updateItemInCart
 */

export function getItemFromLocalStorage(key) {
  try {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  } catch (error) {
    console.error("Error retrieving data from localStorage:", error);
    return null;
  }
}

export function removeItemFromCart(key, index) {
  try {
    if (index) {
      const data = JSON.parse(localStorage.getItem(key));
      data.splice(index, 1);
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.error("Error editing data to localStorage:", error);
  }
}

export function editItemInLocalStorage(key, value) {
  try {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  } catch (error) {
    console.error("Error editing data in localStorage:", error);
  }
}

export function updateItemInCart(key, index, newItem) {
  try {
    const data = JSON.parse(localStorage.getItem(key));
    data[index] = newItem;
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error("Error updating item in localStorage:", error);
  }
}
