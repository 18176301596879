import React from "react";
import { Typography, Box } from "@mui/material";

const DetailSummaryNotes = () => {
  const notes = [
    "Your contract will become effective from the day of payment.",
    "After making the payment you will be able to see your active contract in the Contracts option.",
    "We bill the first week with no refunds, and any overage will be charged separately.",
    "Starting in the second week, the bill is based on daily usage until the next cut-off date.",
    "Billing day will be Tuesdays.",
  ];

  return (
    <Box sx={{ mb: 3 }}>
      <Typography
        color="primary.dark"
        style={{
          fontSize: 12,
          fontWeight: "bold",
        }}
        sx={{ mt: 1 }}
      >
        Notes:
      </Typography>
      {notes.map((note, index) => (
        <Typography
          component="li"
          key={index}
          color="text.primary"
          style={{
            fontSize: 12,
          }}
        >
          {note}
        </Typography>
      ))}
    </Box>
  );
};

export default DetailSummaryNotes;
