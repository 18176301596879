import * as React from "react";

const SnackBarContext = React.createContext();

export const useSnackBar = () => React.useContext(SnackBarContext);

export const SnackBarProvider = ({ children }) => {
  const [SnackBarInformation, setSnackBarInformation] = React.useState({
    SnackBarMessage: "",
    SnackBarState: false,
    SnackBarType: "",
  });

  function handleRenderSnackBar(message, type) {
    setSnackBarInformation({
      SnackBarMessage: message,
      SnackBarState: true,
      SnackBarType: type,
    });
  }
  function handleCloseSnackBar() {
    setSnackBarInformation({
      SnackBarMessage: "",
      SnackBarState: false,
      SnackBarType: "",
    });
  }

  window.addEventListener("customAgreementCartViewSnackBar", (e) => {
    handleRenderSnackBar(e.detail.message, e.detail.type);
  });

  return (
    <SnackBarContext.Provider
      value={{
        SnackBarInformation,
        handleRenderSnackBar,
        handleCloseSnackBar,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};
