import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const FullCardTitle = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Typography
        color="primary.dark"
        style={{
          fontSize: 26,
          fontWeight: "bold",
        }}
      >
        No items in the cart,
      </Typography>
      <Typography
        color="secondary.main"
        style={{
          fontSize: 26,
          fontWeight: "bold",
        }}
      >
        yet!
      </Typography>
    </Box>
  );
};

export default FullCardTitle;
