import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  editItemInLocalStorage,
  getItemFromLocalStorage,
  removeItemFromCart,
} from "./services/localStorageService";
import Container from "@mui/material/Container";
import CartViewContainer from "./containers/CartViewContainer";
import EmptyCartContainer from "./containers/EmptyCartContainer";
import NotificationAlert from "./components/SnackBar/SnackBar";
import Cookies from "universal-cookie";
import { getAgreementById } from "./services/agreementService";
import { getYardOwnerByScacCode } from "./services/YardOwnerServices";
import { getChassisAgreementById } from "./services/ChassisServices";
import { SnackBarProvider, useSnackBar } from "./context/SnackBarContext";

import theme from "./styles/theme";
import "./styles/global.css";

export default function Root(props) {
  return (
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <MainApp />
        <NotificationAlert />
      </SnackBarProvider>
    </ThemeProvider>
  );
}

function MainApp() {
  const cookies = new Cookies();
  const { handleRenderSnackBar } = useSnackBar();
  const [userCart, setUserCart] = React.useState([]);
  const [agreementInCreatedState, setAgreementInCreatedState] =
    React.useState(false);
  const [agreementId, setAgreementId] = React.useState(null);
  const [yardOnwerScac, setYardOnwerScac] = React.useState(null);

  const handleRemoveItemFromCart = (index) => {
    const newCart = [...userCart];
    newCart.splice(index, 1);
    setUserCart(newCart);
    removeItemFromCart("yardCustomProduct", index);
  };

  const formatDate = (date) => {
    const dateSelected = new Date(date);
    return `${("0" + (dateSelected.getMonth() + 1)).slice(-2)}/${(
      "0" + dateSelected.getDate()
    ).slice(-2)}/${dateSelected.getFullYear()}`;
  };

  const getEndDateByFrequency = (frequency, date) => {
    const dateSelected = new Date(date);
    let endDate;

    if (frequency === "Daily") {
      endDate = new Date(dateSelected.getTime() + 6 * 24 * 60 * 60 * 1000); // 7 days after
    } else if (frequency === "Weekly") {
      const currentDay = dateSelected.getDay();
      const daysUntilSaturday = 6 - currentDay; // 6 porque sábado es el día 6 en getDay()
      endDate = new Date(
        dateSelected.getTime() + daysUntilSaturday * 24 * 60 * 60 * 1000
      );
    } else {
      endDate = new Date(dateSelected);
      endDate.setMonth(endDate.getMonth() + 1);
    }

    return `${("0" + (endDate.getMonth() + 1)).slice(-2)}/${(
      "0" + endDate.getDate()
    ).slice(-2)}/${endDate.getFullYear()}`;
  };

  const arePaymentObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleValidateScacCodeToBuy = (scacCodeFromAgreement) => {
    const sessionCosmos = cookies.get("sessionCosmos");
    if (sessionCosmos && sessionCosmos !== undefined) {
      if (sessionCosmos.scac_code === scacCodeFromAgreement) {
        return true;
      }
    } else {
      window.dispatchEvent(new Event("sessionCosmosNotExists"));
    }
    return false;
  };

  const handleCreateObjectFromCustomAgreementResponse = (data) => {
    return {
      id: data.AgreementId,
      idEnvelope: null,
      privacyPolicyAgreed: false,
      StartDate: formatDate(data.AgreementDates.ValidFromUTC),
      EndDate: getEndDateByFrequency(
        data.Service.Frequency,
        data.AgreementDates.ValidFromUTC
      ),
      ProductsChosen: {
        NumberOfSpots: data.Service.SpotsRented,
        YardSelected: data.Service.YardRentedCodeName,
        PriceChosen: {
          Frequency: data.Service.Frequency,
          Regular: data.Service.Regular,
          Overage: data.Service.Overage,
        },
      },
    };
  };

  const handleCreateObjectFromCustomAgreementChassisResponse = (data) => {
    return {
      id: data.AgreementId,
      type: "Chassis",
      idEnvelope: null,
      privacyPolicyAgreed: false,
      StartDate: formatDate(data.AgreementDates.ValidFromUTC),
      EndDate: getEndDateByFrequency(
        data.Service.Frequency,
        data.AgreementDates.ValidFromUTC
      ),
      ProductsChosen: {
        NumberOfSpots: data.Service.ChassisRented,
        YardSelected: data.Service.hassisRentedCodeName,
        PriceChosen: {
          Frequency: data.Service.Frequency,
          Regular: data.Service.Regular,
          Overage: data.Service.Overage,
        },
      },
    };
  };

  const getParamFromUrl = (paramName) => {
    const url = window.location.href;
    const paramIndex = url.indexOf(paramName);
    if (paramIndex !== -1) {
      let paramValue = url.substring(paramIndex + paramName.length);
      const ampersandIndex = paramValue.indexOf("&");
      if (ampersandIndex !== -1) {
        paramValue = paramValue.substring(0, ampersandIndex); // Corta hasta el siguiente '&'
      }
      return paramValue;
    }
    return null;
  };

  const handleGetAgreementById = () => {
    removeItemFromCart("yardProducts");
    removeItemFromCart("paymentObject");

    const customAgreementId = getParamFromUrl("CustomAgreement=");
    const responseAgreementId = getParamFromUrl("AgreementId=");
    const cartInLocalStorage = getItemFromLocalStorage(
      "yardCustomProduct" + customAgreementId
    );
    const cartInLocalStorage2 = getItemFromLocalStorage(
      "yardCustomProduct" + responseAgreementId
    );

    if (
      (cartInLocalStorage && cartInLocalStorage.length > 0) ||
      (cartInLocalStorage2 && cartInLocalStorage2.length > 0)
    ) {
      {
        if (cartInLocalStorage) {
          setUserCart(cartInLocalStorage);
          return;
        } else if (cartInLocalStorage2) {
          setUserCart(cartInLocalStorage2);
          return;
        }
      }
    }
    let agreementId = customAgreementId
      ? customAgreementId
      : responseAgreementId;
    if (agreementId) {
      getAgreementById(agreementId)
        .then((res) => {
          if (res.Status) {
            const data = res;
            if (data.Status === "Created") {
              if (
                !handleValidateScacCodeToBuy(data.CarrierContact.ScacCode) &&
                !handleValidateScacCodeToBuy(data.OwnerScacCode) &&
                cookies.get("sessionCosmos").scac_code !== data.OwnerScacCode &&
                !cookies.get("sessionCosmos").email[0].includes("@snecorp.com")
              ) {
                handleRenderSnackBar(
                  "You are not allowed to buy this product",
                  "error"
                );
                return;
              }
              setAgreementInCreatedState(true);
              const cart = handleCreateObjectFromCustomAgreementResponse(data);
              localStorage.setItem(
                "yardCustomProduct" + agreementId,
                JSON.stringify([cart])
              );
              setUserCart([cart]);
            } else {
              const localStorageCustomPaymentObject = getItemFromLocalStorage(
                "paymentCustomObject" + agreementId
              );
              if (localStorageCustomPaymentObject) {
                handleSetUserCart();
                return;
              }
              handleRenderSnackBar(
                "The agreement was paid or not found, please contact the admin",
                "warning"
              );
              removeItemFromCart("yardCustomProduct" + agreementId);
              setUserCart([]);
            }
          } else {
            const localStorageCustomPaymentObject = getItemFromLocalStorage(
              "paymentCustomObject" + agreementId
            );
            if (localStorageCustomPaymentObject) {
              handleSetUserCart();
              return;
            }
            handleRenderSnackBar(
              "The agreement was paid or not found, please contact the admin",
              "warning"
            );
            removeItemFromCart("yardCustomProduct" + agreementId);
            setUserCart([]);
          }
        })
        .catch((error) => {
          handleRenderSnackBar(
            "The agreement was paid or not found, please contact the admin",
            "warning"
          );
          removeItemFromCart("yardCustomProduct" + agreementId);
          setUserCart([]);
        });
    } else {
      const AgreementIdFromPaymentObject = getParamFromUrl("CustomAgreement=");
      if (AgreementIdFromPaymentObject) {
        const localStorageCustomProduct = getItemFromLocalStorage(
          "yardCustomProduct" + agreementId
        );
        const localStorageCustomPaymentObject = getItemFromLocalStorage(
          "paymentCustomObject" + agreementId
        );
        if (
          localStorageCustomProduct &&
          localStorageCustomProduct.length > 0 &&
          localStorageCustomProduct[0].id === AgreementIdFromPaymentObject &&
          localStorageCustomPaymentObject
        ) {
          setUserCart(localStorageCustomProduct);
          return;
        } else {
          removeItemFromCart("yardCustomProduct" + agreementId);
          removeItemFromCart("paymentCustomObject" + agreementId);
          handleRenderSnackBar(
            "The agreement was paid or not found, please contact the admin",
            "warning"
          );
          setUserCart([]);
        }
      }
    }
  };

  const handleSetUserCart = () => {
    const agreementIdInUrl = getParamFromUrl("CustomAgreement=");
    const yardOwnerScacCideInUrl = getParamFromUrl("YardOwnerScacCode=");
    if (yardOnwerScac) {
      setUserCart(
        getItemFromLocalStorage("yardCustomProduct" + yardOwnerScacCideInUrl)
      );
    } else {
      setUserCart(
        getItemFromLocalStorage("yardCustomProduct" + agreementIdInUrl)
      );
    }
  };

  const handleCleanYardProductOfLocalStorage = () => {
    const agreementIdInUrl = getParamFromUrl("CustomAgreement=");
    const yardOwnerScacCideInUrl = getParamFromUrl("YardOwnerScacCode=");
    const sessionCosmos = cookies.cookies.sessionCosmos;
    if (!sessionCosmos) {
      if (yardOnwerScac) {
        localStorage.removeItem("yardCustomProduct" + yardOwnerScacCideInUrl);
      } else {
        localStorage.removeItem("yardCustomProduct" + agreementIdInUrl);
      }
    }
  };

  const handleCreateSessionCosmosRemovedListener = () => {
    const sessionCosmos = cookies.cookies.sessionCosmos;
    if (sessionCosmos) {
      cookies.addChangeListener(handleCleanYardProductOfLocalStorage);
    }
  };

  function handleConstructPaymentYardOwnerObject(data) {
    return {
      id: data.id,
      type: "YardOwner",
      idEnvelope: null,
      CarrierClient: data.ScacCode,
      privacyPolicyAgreed: false,
      StartDate: formatDate(new Date()),
      EndDate: getEndDateByFrequency("Monthly", new Date()),
      ProductsChosen: {
        NumberOfSpots: 1,
        YardSelected: "YMS Draypower - Yard Owner",
        PriceChosen: {
          Frequency: "Monthly",
          Regular: [
            {
              Code: 1,
              Description: "Yard Management System (Monthly)",
              Cost: 1500,
              Type: "YMS",
              Overage: false,
            },
            {
              Code: 2,
              Description: "Yard Package - Yard Management System",
              Cost: 500,
              Type: "Yard-Package",
              Overage: false,
            },
          ],
          Overage: [],
        },
      },
    };
  }

  //YARD OWNER FUNCTIONS ------------------------------------------------------------
  function handleYardOwnerItemProcess(ScacCode) {
    editItemInLocalStorage("YardOwnerCustomInComing", {
      ScacCode: ScacCode,
    });
    if (cookies.get("sessionCosmos")) {
      removeItemFromCart("yardProducts");
      removeItemFromCart("paymentObject");
      if (
        ScacCode !== cookies.get("sessionCosmos").scac_code &&
        !cookies.get("sessionCosmos").email[0].includes("@snecorp.com")
      ) {
        handleRenderSnackBar(
          "You are not allowed to buy this product",
          "error"
        );

        return;
      }
      if (
        getItemFromLocalStorage("yardCustomProduct" + ScacCode) ||
        getItemFromLocalStorage("paymentCustomObject" + ScacCode)
      ) {
        setUserCart(getItemFromLocalStorage("yardCustomProduct" + ScacCode));
      }
      const paymentOBjectExisting = getItemFromLocalStorage(
        "paymentCustomObject" + ScacCode
      );
      getYardOwnerByScacCode(ScacCode)
        .then((res) => {
          if (
            res.status === "Approved" ||
            (res.status === "Enabled" && paymentOBjectExisting)
          ) {
            const payloadToCart = handleConstructPaymentYardOwnerObject(res);

            localStorage.setItem(
              "yardCustomProduct" + ScacCode,
              JSON.stringify([payloadToCart])
            );
            setUserCart([payloadToCart]);
          } else if (res.status === "Created") {
            handleRenderSnackBar(
              "Your Yard Owner is not aproved yet, please contact the admin",
              "warning"
            );
            removeItemFromCart("yardCustomProduct" + ScacCode);
            removeItemFromCart("YardOwnerCustomInComing");
            setUserCart([]);
          } else {
            handleRenderSnackBar(
              "The product was paid or not found, please contact to admin",
              "warning"
            );
            removeItemFromCart("YardOwnerCustomInComing");
            removeItemFromCart("yardCustomProduct" + ScacCode);
            setUserCart([]);
          }
        })
        .catch((error) => {
          handleRenderSnackBar(
            "The product was paid or not found, please contact to admin",
            "warning"
          );
          removeItemFromCart("YardOwnerCustomInComing");
          removeItemFromCart("yardCustomProduct" + ScacCode);
          setUserCart([]);
        });
    } else {
      window.dispatchEvent(new Event("sessionCosmosNotExists"));
    }
  }

  const handleCheckAndCleanCustomObjectsFromLocalStorage = () => {
    let paymentObject = null;
    let keyOfObject = "";
    let yardCustomObjectkey = "";
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.includes("paymentCustomObject")) {
        paymentObject = getItemFromLocalStorage(key);
        keyOfObject = key;
      }
      if (key.includes("yardCustomProduct")) {
        yardCustomObjectkey = key;
      }
    }

    if (paymentObject?.length > 0 && paymentObject[0]?.paymentStatus) {
      removeItemFromCart(keyOfObject);
      removeItemFromCart(yardCustomObjectkey);
      removeItemFromCart("YardOwnerCustomInComing");
    }
  };

  //CHASSIS FUNCTIONS ------------------------------------------------------------

  function handleGetChassisAgreementById() {
    removeItemFromCart("yardProducts");
    removeItemFromCart("paymentObject");

    const customAgreementId = getParamFromUrl("CustomAgreement=");
    const responseAgreementId = getParamFromUrl("AgreementId=");
    const cartInLocalStorage = getItemFromLocalStorage(
      "yardCustomProduct" + customAgreementId
    );
    const cartInLocalStorage2 = getItemFromLocalStorage(
      "yardCustomProduct" + responseAgreementId
    );
    if (
      (cartInLocalStorage && cartInLocalStorage.length > 0) ||
      (cartInLocalStorage2 && cartInLocalStorage2.length > 0)
    ) {
      {
        if (cartInLocalStorage) {
          setUserCart(cartInLocalStorage);
          return;
        } else if (cartInLocalStorage2) {
          setUserCart(cartInLocalStorage2);
          return;
        }
      }
    }
    let agreementId = customAgreementId
      ? customAgreementId
      : responseAgreementId;
    if (agreementId) {
      getChassisAgreementById(agreementId)
        .then((res) => {
          if (res.Status) {
            const data = res;
            if (data.Status === "Approved") {
              if (
                !handleValidateScacCodeToBuy(data.CarrierContact.ScacCode) &&
                !handleValidateScacCodeToBuy(data.OwnerScacCode) &&
                cookies.get("sessionCosmos").scac_code !== data.OwnerScacCode &&
                !cookies.get("sessionCosmos").email[0].includes("@snecorp.com")
              ) {
                handleRenderSnackBar(
                  "You are not allowed to buy this product",
                  "error"
                );
                return;
              }
              setAgreementInCreatedState(true);
              const cart =
                handleCreateObjectFromCustomAgreementChassisResponse(data);
              localStorage.setItem(
                "yardCustomProduct" + agreementId,
                JSON.stringify([cart])
              );
              setUserCart([cart]);
            } else if (res.Status === "Created") {
              const localStorageCustomPaymentObject = getItemFromLocalStorage(
                "paymentCustomObject" + agreementId
              );
              if (localStorageCustomPaymentObject) {
                handleSetUserCart();
                return;
              }
              handleRenderSnackBar(
                "The agreement was no approved yet, please contact the admin",
                "warning"
              );
              removeItemFromCart("yardCustomProduct" + agreementId);
              setUserCart([]);
            } else {
              const localStorageCustomPaymentObject = getItemFromLocalStorage(
                "paymentCustomObject" + agreementId
              );
              if (localStorageCustomPaymentObject) {
                handleSetUserCart();
                return;
              }
              handleRenderSnackBar(
                "The agreement was paid or not found, please contact the admin",
                "warning"
              );
              removeItemFromCart("yardCustomProduct" + agreementId);
              setUserCart([]);
            }
          } else {
            const localStorageCustomPaymentObject = getItemFromLocalStorage(
              "paymentCustomObject" + agreementId
            );
            if (localStorageCustomPaymentObject) {
              handleSetUserCart();
              return;
            }
            handleRenderSnackBar(
              "The agreement was paid or not found, please contact the admin",
              "warning"
            );
            removeItemFromCart("yardCustomProduct" + agreementId);
            setUserCart([]);
          }
        })
        .catch((error) => {
          handleRenderSnackBar(
            "The agreement was paid or not found, please contact the admin",
            "warning"
          );
          removeItemFromCart("yardCustomProduct" + agreementId);
          setUserCart([]);
        });
    } else {
      const AgreementIdFromPaymentObject = getParamFromUrl("CustomAgreement=");
      if (AgreementIdFromPaymentObject) {
        const localStorageCustomProduct = getItemFromLocalStorage(
          "yardCustomProduct" + agreementId
        );
        const localStorageCustomPaymentObject = getItemFromLocalStorage(
          "paymentCustomObject" + agreementId
        );
        if (
          localStorageCustomProduct &&
          localStorageCustomProduct.length > 0 &&
          localStorageCustomProduct[0].id === AgreementIdFromPaymentObject &&
          localStorageCustomPaymentObject
        ) {
          setUserCart(localStorageCustomProduct);
          return;
        } else {
          removeItemFromCart("yardCustomProduct" + agreementId);
          removeItemFromCart("paymentCustomObject" + agreementId);
          handleRenderSnackBar(
            "The agreement was paid or not found, please contact the admin",
            "warning"
          );
          setUserCart([]);
        }
      }
    }
  }

  //USE EFFECTS ------------------------------------------------------------
  React.useEffect(() => {
    const agreementIdInUrl = getParamFromUrl("CustomAgreement=");
    const yardOwnerScacCideInUrl = getParamFromUrl("YardOwnerScacCode=");
    const agreementIdResponse = getParamFromUrl("AgreementId=");
    const Type = getParamFromUrl("Type=");

    function actionYardOwner() {
      const YardOwnerCustomInComing = getItemFromLocalStorage(
        "YardOwnerCustomInComing"
      );
      handleYardOwnerItemProcess(
        yardOwnerScacCideInUrl
          ? yardOwnerScacCideInUrl
          : YardOwnerCustomInComing.ScacCode
      );
    }

    if (
      agreementIdInUrl ||
      (agreementIdResponse && !localStorage.getItem("YardOwnerCustomInComing"))
    ) {
      if (cookies.get("sessionCosmos")) {
        if (Type !== "Chassis") handleGetAgreementById();
        else handleGetChassisAgreementById();
      } else {
        window.addEventListener("UserDataLoaded", () => {
          if (Type !== "Chassis") handleGetAgreementById();
          else handleGetChassisAgreementById();
        });
      }
    }
    if (
      yardOwnerScacCideInUrl ||
      localStorage.getItem("YardOwnerCustomInComing")
    ) {
      if (cookies.get("sessionCosmos")) {
        actionYardOwner();
      } else {
        window.addEventListener("UserDataLoaded", () => {
          actionYardOwner();
        });
      }
    }

    window.addEventListener("localStorageHasChanged", handleSetUserCart);
    cookies.addChangeListener(handleCreateSessionCosmosRemovedListener);
  }, []);
  window.addEventListener(
    "beforeunload",
    handleCheckAndCleanCustomObjectsFromLocalStorage
  );
  window.addEventListener(
    "popstate",
    handleCheckAndCleanCustomObjectsFromLocalStorage
  );
  return (
    <Container maxWidth={false}>
      {userCart.length > 0 ? (
        <CartViewContainer
          userCart={userCart}
          onRemoveItemFromCart={handleRemoveItemFromCart}
        />
      ) : (
        <EmptyCartContainer />
      )}
    </Container>
  );
}
