import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
  Backdrop,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TermsOfUseDialog = ({ activeProduct, onPrivacyPolicyChange }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [termsContent, setTermsContent] = useState("");

  useEffect(() => {
    // Función para recuperar el contenido de los términos de uso desde Azure Blob Storage
    async function fetchTermsContent() {
      const response = await fetch(
        process.env.REACT_APP_GET_DOCS_STORAGE_URL + "PrivacyPolicy.html"
      );
      const downloadedContent = await response.text();

      setTermsContent(downloadedContent);
    }

    fetchTermsContent();
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    onPrivacyPolicyChange(true);
    setDialogOpen(false);
  };

  return (
    <>
      <Typography
        variant="body2"
        color="primary"
        onClick={handleOpenDialog}
        style={{ cursor: "pointer", fontSize: 16 }}
      >
        Privacy Policy
      </Typography>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        scroll="paper"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: {
            backdropFilter: "blur(26px)",
            backgroundColor: "rgba(34, 34, 79, 0.30)",
          },
        }}
      >
        <DialogTitle style={{ fontSize: 40, fontWeight: "bold" }}>
          Privacy Policy
        </DialogTitle>
        <DialogContent
          dividers
          dangerouslySetInnerHTML={{ __html: termsContent }}
        />
        <DialogActions sx={{ p: 4 }}>
          <Button
            onClick={handleCloseDialog}
            size="large"
            variant="contained"
            color="secondary"
            sx={{ width: 150, borderRadius: 2 }}
          >
            <Typography textTransform="capitalize" variant="button">
              Accept
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsOfUseDialog;
