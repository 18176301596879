// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentPage-stripe-embedded-container {
    display: grid;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
}

.PaymentPage-embedded-object {
    width: 100%;
    height: 100%;


}`, "",{"version":3,"sources":["webpack://./src/components/StripeEmbedd/PaymentPage/PaymentPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;;;AAGhB","sourcesContent":[".PaymentPage-stripe-embedded-container {\n    display: grid;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    background-color: #f5f5f5;\n}\n\n.PaymentPage-embedded-object {\n    width: 100%;\n    height: 100%;\n\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
