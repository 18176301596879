import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const getAgreementById = async (AgreementId) => {
  const config = {
    headers,
    url: process.env.REACT_APP_GET_AGREEMENT_BY_ID,
    method: "GET",
    params: {
      AgreementId,
    },
    nameEvent: "CustomAgreementCartview-getAgreementById",
  };

  try {
    const response = await axiosInstance(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};
