import * as React from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import FullCardTitle from "./FullCardTitle";

const FullCard = () => {
  function handleRedirectToProducts() {
    window.history.pushState({}, null, "/landing-page/products");
  }
  return (
    <Paper
      elevation={2}
      sx={{
        minHeight: 100,
        borderRadius: 5,
        bgcolor: "white.main",
        zIndex: "bottom",
        px: 3,
        py: 2,
        mb: 3,
        mt: 2,
      }}
    >
      <FullCardTitle />
      <Divider
        variant="fullWidth"
        sx={{ borderColor: "primary.dark", border: 1 }}
      />

      <Button
        size="large"
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ borderRadius: 2, mt: 2, mb: 1 }}
        onClick={handleRedirectToProducts}
      >
        <Typography textTransform="capitalize" variant="button">
          Go back to the store
        </Typography>
      </Button>
    </Paper>
  );
};

export default FullCard;
