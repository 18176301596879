/**
 * Formats a float as a US Dollar.
 * @param {number} amount - The float value to format.
 * @returns {string} - The formatted US Dollar string.
 */
export function formatAsUSD(amount) {
  if (amount === null || amount === undefined) {
    return "$--.--";
  }

  const formattedAmount = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formattedAmount;
}

/**
 * Formats a float as a US Dollar with "/Day" appended.
 * @param {number} amount - The float value to format.
 * @returns {string} - The formatted US Dollar string with "/Day" appended.
 */
export function formatAsUSDDay(amount) {
  if (amount === null || amount === undefined) {
    return "";
  }

  const formattedAmount = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return `${formattedAmount}/Day`;
}
