import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

const DetailSummaryFooter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: 1,
        mx: 1,
        mt: 2,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      <Avatar
        alt="X"
        src={process.env.REACT_APP_GET_IMAGES_URL + "RosaMota.png"}
        sx={{ width: 84, height: 84 }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          p: 1,
          my: 1,
          ml: 2,
          mr: 1,
        }}
      >
        <InfoOutlinedIcon color="primary.dark" sx={{ fontSize: 20 }} />
        <Typography
          color="text.primary"
          gutterBottom
          sx={{
            ml: 1,
          }}
          style={{
            fontSize: 13,
          }}
        >
          In case of inconsistency or any other issues with the purchase
          process, you can contact
          <strong> help@dray-power.com</strong>, one of our advisors will help
          you.
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailSummaryFooter;
