import { axiosInstance } from "./appInsights";

const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const getYardOwnerByScacCode = async (ScacCode) => {
  const config = {
    headers,
    url: process.env.REACT_APP_GET_YARD_OWNER_BY_SCAC,
    method: "GET",
    params: {
      ScacCode,
    },
    nameEvent: "CustomAgreementCartview-getYardOwnerByScacCode",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const createYardOwnerSubscription = async (payload) => {
  const config = {
    headers,
    url: process.env.REACT_APP_CREATE_YARD_OWNER_SUBSCRIPTION,
    method: "POST",
    data: payload,
    nameEvent: "CustomAgreementCartview-createYardOwnerSubscription",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.error(err);
    return error;
  }
};
