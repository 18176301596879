// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .LoginReact-circular-progress {
    color: #fc4817;
    scale: 2;
}

body .LoginReact-circular-progress-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}`, "",{"version":3,"sources":["webpack://./src/components/CartView/DetailSummary/DetailSummary.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;;AAEhB","sourcesContent":["body .LoginReact-circular-progress {\n    color: #fc4817;\n    scale: 2;\n}\n\nbody .LoginReact-circular-progress-box {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
