import dayjs from "dayjs";

/**
 * Calculate the number of days between two dates
 * @param {string} date1 - The first date in MM/DD/YYYY format.
 * @param {string} date2 - The second date in MM/DD/YYYY format.
 * @returns {number|null} - The number of days between the two dates, or null if either date is null.
 */
export function calculateDaysBetweenDates(date1, date2) {
  if (date1 === null || date2 === null) {
    return null;
  }

  const formattedDate1 = dayjs(date1, "MM/DD/YYYY");
  const formattedDate2 = dayjs(date2, "MM/DD/YYYY");
  return formattedDate2.diff(formattedDate1, "day") + 1;
}

/**
 * Calculate the number of days between two dates and return a string
 * @param {string} date1 - The first date in MM/DD/YYYY format.
 * @param {string} date2 - The second date in MM/DD/YYYY format.
 * @returns {string|null} - The number of days between the two dates as a string, or null if either date is null.
 */
export function calculateDaysBetweenDatesText(date1, date2, type) {
  if (date1 === null || date2 === null) {
    return null;
  }

  const formattedDate1 = dayjs(date1, "MM/DD/YYYY");
  const formattedDate2 = dayjs(date2, "MM/DD/YYYY");
  const diffInDays =
    type === "Daily"
      ? formattedDate2.diff(formattedDate1, "day")
      : formattedDate2.diff(formattedDate1, "day") + 1;
  return `${diffInDays} Day${diffInDays === 1 ? "" : "s"}`;
}

/**
 * Get the current date in YYYY-MM-DD format
 * @returns {string} - The current date in YYYY-MM-DD format
 */
export function getCurrentDate() {
  return dayjs().format("YYYY-MM-DD");
}
